import React, { useEffect } from 'react'
import { CodeCopyBtn, CodeInput, CodeSpace, CodeSubmit, EnterCodeDiv, FormElem, Heading, Icons, IconsDiv, IndivisualInvitorsList, InvitationHolder, InvitationList, InvitationStatusDiv, InviteCodeDiv, InviteImg, ProfilePic, ReferContainer, ShareDiv, TitleBox, TopDiv, UserNameDiv } from './InviteContainer.styled'
import { useState } from 'react'
import inviteLogo from '../../pictures/inviteLogo.svg'
import whatsapp from '../../pictures/whatsapp 1.png'
import facebook from '../../pictures/facebook 1.png'
import instagram from '../../pictures/social 1.png'
import axios from 'axios'
import useFooddyStore from '../../store'
import { Form, useParams } from 'react-router-dom'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import man1Icon from "../../assets/menEmoji/man1.png";
import man2Icon from "../../assets/menEmoji/man2.png";
import man3Icon from "../../assets/menEmoji/man3.png";
import man4Icon from "../../assets/menEmoji/man4.png";
import man5Icon from "../../assets/menEmoji/man5.png";
import man6Icon from "../../assets/menEmoji/man6.png";
import man7Icon from "../../assets/menEmoji/man7.png";
import man8Icon from "../../assets/menEmoji/man8.png";

// import im from '../../assets/menEmoji/man1.png'

import woman1Icon from "../../assets/womenEmoji/woman1.png";
import woman2Icon from "../../assets/womenEmoji/woman2.png";
import woman3Icon from "../../assets/womenEmoji/woman3.png";
import woman4Icon from "../../assets/womenEmoji/woman4.png";
import woman5Icon from "../../assets/womenEmoji/woman5.png";
import woman6Icon from "../../assets/womenEmoji/woman6.png";
import woman7Icon from "../../assets/womenEmoji/woman7.png";
import woman8Icon from "../../assets/womenEmoji/woman8.png";
import woman9Icon from "../../assets/womenEmoji/woman9.png";
import woman10Icon from "../../assets/womenEmoji/woman10.png";
import woman11Icon from "../../assets/womenEmoji/woman11.png";
import woman12Icon from "../../assets/womenEmoji/woman12.png";
let menImages = [
  man1Icon,
  man2Icon,
  man3Icon,
  man4Icon,
  man5Icon,
  man6Icon,
  man7Icon,
  man8Icon,
  man3Icon,
  man7Icon,
  man8Icon,
  man2Icon
]
let womenImages = [
  woman1Icon,
  woman2Icon,
  woman3Icon,
  woman4Icon,
  woman5Icon,
  woman6Icon,
  woman7Icon,
  woman8Icon,
  woman9Icon,
  woman10Icon,
  woman11Icon,
  woman12Icon,
]

function getCookieValue() {
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(`invitecode=`)) {
      return cookie.substring('invitecode='.length);
    }
  }
  return null;
}

const InviteContainer = () => {
  let params = useParams()
  const {
    baseUrl,
    inviteCode,
    setInviteCode,
    userId,
    friends,
    swiggyActive,
    totalAmount,
    userName
  } = useFooddyStore()
  const [toggle, setToggle] = useState(true)
  const [invitationCode, setInvitationCode] = useState('')
  const [invitationStatusText, setInvitationStatusText] = useState('')
  const [copyState, setCopyState] = useState(false)
  let currentUrl = `https://app.ecombullet.com/invitation/${userName}/${inviteCode}`
  const fetchInviteCode = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/invitation/invitecode`, {
        mapUserId: params.userid,
      })
      if (response?.data) {
        setInviteCode(response.data.invitationCode)
      }
    } catch (error) { console.log(error) }
  }

  const handleCopy = () => {
    if (navigator.clipboard && window.isSecureContext) {
      // Navigator clipboard API method
      navigator.clipboard.writeText(inviteCode);
      setCopyState(true)
      setTimeout(() => {
        setCopyState(false)
      }, 3000)
    } else {
      // Fallback method for older browsers
      const textarea = document.createElement('textarea');
      textarea.value = inviteCode;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      setCopyState(true)
      setTimeout(() => {
        setCopyState(false)
      }, 3000)
    }
  }

  useEffect(() => {
    if (userId) fetchInviteCode()
  }, [userId])
  useEffect(() => {
  }, [inviteCode])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await axios.post(`${baseUrl}/api/invitation/inviteWithCode`, {
        invitationCode: invitationCode,
        accepterId: userId
      })
      if (response?.data) {
        setInvitationStatusText(response.data)
      }
    } catch (error) { console.log(error) }
  }
  const invitationFromCookie = async () => {
    try {
      const code = getCookieValue()
      if (code) {
        const response = await axios.post(`${baseUrl}/api/invitation/inviteWithCode`, {
          invitationCode: code,
          accepterId: userId
        })
      }
    } catch (error) { console.log(error) }
  }
  useEffect(() => {
    invitationFromCookie()
  }, [userId])
  useEffect(() => {
    if (invitationStatusText) {
      setTimeout(() => {
        setInvitationStatusText('')
      }, 3000)
    }
  }, [invitationStatusText])
  return (
    <ReferContainer>
      <TopDiv>
        <TitleBox onClick={() => { setToggle(true) }} active={toggle}>Invite</TitleBox>
        <TitleBox onClick={() => { setToggle(false) }} active={!toggle}>Referral</TitleBox>
      </TopDiv>
      {toggle ? (
        <>
          <Heading>Refer Shopping Buddies, and Earn Rewards</Heading>
          <InviteImg src={inviteLogo} alt="inviteIcon" />
          <InviteCodeDiv>
            <CodeSpace>{inviteCode}</CodeSpace>
            {
              !copyState ? <CodeCopyBtn onClick={handleCopy} enable>Copy Code</CodeCopyBtn> : <CodeCopyBtn disable>Copied..</CodeCopyBtn>
            }

          </InviteCodeDiv>
          <p style={{ lineHeight: "5px", fontFamily: 'Poppins' }}>Share your referral code via</p>
          <ShareDiv>
            <IconsDiv>
              <WhatsappShareButton
                url={currentUrl}
                title={`Just tallied up my ${!swiggyActive ? "flipkart" : "amazon"
                  } online ecom expenses: a whopping ${totalAmount}! 🍽️💸 I was shocked to see my spending add up like this. 😅 Have you checked yours? Let's compare! Connect your friend with -- ${inviteCode}`}

              >
                <WhatsappIcon size={25} round />
              </WhatsappShareButton>
            </IconsDiv>
            <IconsDiv>
              <FacebookShareButton
                url={currentUrl}
                quote={`Just tallied up my ${!swiggyActive ? "flipkart" : "amazon"
                  } online ecom expenses: a whopping ${totalAmount}! 🍽️💸 I was shocked to see my spending add up like this. 😅 Have you checked yours? Let's compare! Connect your friend with ${inviteCode}\n`}
                hashtag="#Ecom"
              >
                <FacebookIcon size={25} round />
              </FacebookShareButton>
            </IconsDiv>
            <IconsDiv>
              <TwitterShareButton
                url={currentUrl}
                title={`Just tallied up my ${!swiggyActive ? "flipkart" : "amazon"
                  } online ecom expenses: a whopping ${totalAmount}! 🍽️💸 I was shocked to see my spending add up like this. 😅 Have you checked yours? Let's compare! 
My Ecom report: Connect your friend with ${inviteCode}\n\n`}
                hashtags={[
                  "ecom",
                  "amazon",
                  "flipkart",
                  "spending_calculator",
                ]}
              >
                <TwitterIcon size={25} round />
              </TwitterShareButton>
            </IconsDiv>
          </ShareDiv>
        </>
      ) : (
        <>
          <InvitationList>
            {
              friends.map((e, i) => {
                return (
                  <>
                    {
                      e.gender === 'male' ? (<IndivisualInvitorsList>
                        <ProfilePic src={menImages[i]} />
                        <UserNameDiv>{e.user_name}</UserNameDiv>
                      </IndivisualInvitorsList>) : (<IndivisualInvitorsList>
                        <ProfilePic src={womenImages[i]} />
                        <UserNameDiv></UserNameDiv>
                      </IndivisualInvitorsList>)
                    }
                  </>
                )

              })
            }
          </InvitationList>
          {typeof invitationStatusText === 'string' ? (<InvitationHolder fail>
            <InvitationStatusDiv fail>{invitationStatusText}</InvitationStatusDiv>
            <EnterCodeDiv>
              <FormElem onSubmit={handleSubmit}>
                <CodeInput type='text' placeholder='Enter refferal code' onChange={(event) => {
                  setInvitationCode(event.target.value)
                  setInvitationStatusText('')
                }} />
                <CodeSubmit>submit</CodeSubmit>
              </FormElem>
            </EnterCodeDiv>
          </InvitationHolder>) : (<InvitationHolder success>
            <InvitationStatusDiv success>Invitation Successfull</InvitationStatusDiv>
            <EnterCodeDiv>
              <FormElem onSubmit={handleSubmit}>
                <CodeInput type='text' placeholder='Enter refferal code' onChange={(event) => {
                  setInvitationCode(event.target.value)
                  setInvitationStatusText('')
                }} />
                <CodeSubmit>submit</CodeSubmit>
              </FormElem>
            </EnterCodeDiv>
          </InvitationHolder>)}
        </>
      )}
    </ReferContainer>
  )
}

export default InviteContainer
