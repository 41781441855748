import styled from "styled-components";

export const RightContainerWrapper = styled.div`
width: 380px;
height: 100%;
flex-shrink: 0;
background: #FFF1D2;
border-radius: 0px 40px 40px 0px;
display: flex;
    flex-direction: column;
    align-items: center;
position: relative;

`

export const ImageWrapper = styled.div`
display: flex;
    width: 45px;
    /* border: 1px solid red; */
    border-radius: 50%;
    margin: 0px 15px 0px 10px;
    /* position: relative; */
    align-items: center;
    background-color: rgba(130, 130, 130, 0.2);
    justify-content: center;

`
export const UserInfDiv = styled.div`
margin-top: 30px;
width: 100%;
height: 65px;
flex-shrink: 0;
border-radius: 0px 40px 0px 0px;
display: flex;
align-items: center;
justify-content: flex-end;
color: #0D062D;
text-align: right;
font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;

`
export const UserImg = styled.img`
   
    height: 40px;
`

export const PromoImgDiv = styled.img`
  height: 150px;
`

export const LeaderBoardTextDiv = styled.div`
width:100%;
height: 21px;
/* background-color: red; */
margin-top: 12px;
color: #3C3C3C;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.54px;
padding-left: 69px;
`
export const LeaderBoardContainer = styled.div`
width: 100%;
/* background-color: red; */
/* height: 100px; */
margin-top: 10px;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
`

export const LeaderboardHeader = styled.div`
width: 315px;
height: 40px;
flex-shrink: 0;
border-radius: 12px;
background: #FFD9A2;
display: flex;
align-items: center;
/* justify-content: space-around; */
color: #4A4A4A;
text-align: center;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 175% */
padding-left: 29px;
box-sizing: border-box;
`
export const AllLeaderBoardheader = styled.div`
    display: flex;
    align-items: center;
    padding: 7px 15px 6px 13px;
    border-radius: 12px;
    /* background: #F6EBDA; */
    background-color: ${props => props.bgColor ? "none" : "#F6EBDA"};
    cursor: pointer;
`


export const MonthlyLeaderBoardheader = styled.div`
    display: flex;
    align-items: center;
    padding: 7px 15px 6px 13px;
    border-radius: 12px;
    /* background: #F6EBDA; */
    background-color: ${props => props.bgColor ? "#F6EBDA" : "none"};

    cursor: pointer;

`
export const LeaderBoardHeaderImg = styled.img`
margin-right: 5px;

`
